.swiper-review .review-box {
	background-color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 20px;
	flex-direction: column;
	text-align: center;
	padding: 15px 30px;
	border-radius: var(--main-border-radius-02);
	box-shadow: 0px 3px 6px #02466a0f;
	min-height: 180px;
	max-height: 180px;
}

.swiper-review .review-box .box-img {
	width: 65px;
	height: 65px;
}

.swiper-review .review-box .box-img img {
	max-width: 100%;
	height: 100%;
}

.swiper-review .review-box h5 {
	font-size: var(--font-size-18);
	color: var(--black-color-01);
	letter-spacing: 0.18px;
	text-align: center;
	line-height: 1.7;
	font-weight: 500;
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	white-space: pre-wrap;
}
