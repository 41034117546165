.blog-hero {
	padding-bottom: 40px;
}

.blog-hero .container {
	display: flex;
	align-items: center;
	justify-content: center;
}

.blog-hero .container .all {
	flex: 1;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 28px;
	height: 160px;
	position: relative;
	background-image: url(../../assets/Img/img02.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	margin: 0 !important;
}

.blog-hero .container .all h4 {
	color: var(--font-color-04);
	font-weight: 600;
	font-size: var(--font-size-54);
	margin-bottom: 20px;
}

.blog-hero .container .all .form {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: -35px;
	height: 70px;
	width: 100%;
	max-width: 535px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row-reverse;
	background-color: #ffffff;
	border-radius: var(--main-border-radius-16);
	overflow: hidden;
}

.blog-hero .container .all .form input {
	flex: 1;
	height: 100%;
	border: none;
	outline: none;
	background-color: transparent;
	color: var(--black-color-01);
	font-weight: 400;
}

.blog-hero .container .all .form input::placeholder {
	color: var(--font-color-03);
}

.blog-hero .container .all .form button {
	all: unset;
	width: 60px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
