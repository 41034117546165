.search_suggestions_result_wrapper {
	position: absolute;
	top: 70px;
	right: 175px;
	width: 365px;
	max-height: 375px;
	border: 1px solid #fefefe;
	background-color: #fff;
	direction: rtl;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
	overflow-y: auto;
	z-index: 9999;
}

.search_suggestions_result_wrapper .img-box {
	width: 3rem;
	height: 3rem;
	border-radius: 50%;
	border: 1px solid #ddd;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
}

.search_suggestions_result_wrapper .img-box img {
	height: 3rem;
	width: auto;
}

/* Custom scroll style */
.search_suggestions_result_wrapper::-webkit-scrollbar {
	width: 8px;
}
@media (max-width: 992px) {
	.search_suggestions_result_wrapper {
		top: 50px;
		width: 350px;
	}
}
@media (max-width: 768px) {
	.search_suggestions_result_wrapper {
		position: fixed;
		top: 150px;
		left: auto;
		right: auto;
		bottom: auto;
		width: 83%;
		margin: 0 auto;
		border: 1px solid #eff9ff;
		box-shadow: var(--box-shadow-01);

		max-width: 100%;
	}
}

.search_suggestions_result_wrapper li {
	padding: 6px 12px;
	border-bottom: 1px solid var(--font-color-06);
	cursor: pointer;
}

.search_suggestions_result_wrapper li:hover {
	background-color: #f7fcff;
}

.search_suggestions_result_wrapper li .section_name {
	color: var(--black-color-01);
	font-size: 16px;
	font-weight: 400;
}
