.loading-box {
	height: 200px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.loading-box h3 {
	color: var(--font-color-02);
	font-weight: 400;
	font-size: var(--font-size-28);
}
