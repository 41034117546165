.policy-box-main {
	min-height: 900px;
	background-color: #fff;
	padding-top: 80px;
	max-width: 1920px;
}

.policy-box-main .container {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-bottom: 60px;
}

.policy-box-main .container .all {
	max-width: 1364px;
	display: flex;
	align-items: flex-start;
	justify-content: start;
	flex-direction: column;
	gap: 40px;
}

.policy-box-main .container .all .box {
	display: flex;
	align-items: flex-start;
	justify-content: start;
	flex-direction: column;
}

.policy-box-main .container .all .box .info {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 14px;
	margin-bottom: 26px;
}

.policy-box-main .container .all .box .info h5 {
	margin: 0;
	color: var(--font-color-02);
	font-size: var(--font-size-18);
	letter-spacing: 0.18px;
	font-weight: var(--font-weight-03);
}

.atlbha-academy .content .policy-content {
	font-size: var(--font-size-20);
	color: var(--black-color-01);
	margin: 0;
	font-weight: var(--font-weight-02);
	letter-spacing: 0.2px;
}

.policy-content ul {
	list-style: inside;
}
