.owner-box {
	width: 100%;
	height: 65px;
	margin: 10px 0;
}

.owner-box .box {
	display: flex;
	align-items: center;
	justify-content: start !important;
	gap: 10px;
	width: 100%;
	height: 100%;
}

.owner-box .box .box-img {
	width: 48px !important;
	height: 48px !important;
	flex: none !important;
	border-radius: 50% !important;
	overflow: hidden !important;
}

.owner-box .box .box-img img {
	max-width: 100%;
	max-height: 100%;
}

.owner-box .box .info {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	gap: 7px;
	flex-direction: column;
}

.owner-box .box .info h5 {
	margin: 0;
	color: var(--black-color-01);
	font-size: var(--font-size-22);
	font-weight: var(--font-weight-03);
}

.owner-box .box .info h6 {
	margin: 0;
	color: var(--font-color-05);
	font-size: var(--font-size-18);
	font-weight: var(--font-weight-01);
}
