/*======================
    404 page
=======================*/

.NotFoundPage {
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 3000;
	left: 0;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
}

.NotFoundPage .four_zero_four_bg {
	width: 100%;
	height: 100%;
	margin: 60px 0 10px 0;
	position: relative;
}

.NotFoundPage .contant_box_404 {
	position: absolute;
	width: 100%;
	top: 0;
	right: 100%;
	transform: translate(100%, 50%);
}

.NotFoundPage .four_zero_four_bg h3 {
	font-size: 60px;
}
.NotFoundPage .four_zero_four_bg p {
	font-size: 36px;
}

.NotFoundPage .link_404 {
	cursor: pointer;
	color: #fff;
	font-size: 22px;
	padding: 10px 40px;
	background: var(--main-color-01);
	margin: 20px 0;
	display: inline-block;
	border-radius: 2px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	-ms-border-radius: 2px;
	-o-border-radius: 2px;
}

@media (max-width: 768px) {
	.NotFoundPage .four_zero_four_bg svg {
		width: 100%;
		height: 100%;
	}

	.NotFoundPage .four_zero_four_bg h3 {
		font-size: 28px;
	}
	.NotFoundPage .four_zero_four_bg p {
		font-size: 18px;
	}

	.NotFoundPage .link_404 {
		font-size: 18px;
		width: 100%;
		padding: 10px;
		margin: 0;
	}
}
