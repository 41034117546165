.page-title {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 28px;
    height: 160px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 40px 0;
}

.page-title h4 {
    font: normal normal bold 28px/42px Tajawal;
    color: #F7FCFF;
    letter-spacing: 0.56px;
    margin-bottom: 0;
}

.page-sub-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    margin-top: 30px;
}

.page-sub-title span {
    font: normal normal normal 18px/25px Tajawal;
    letter-spacing: 0.36px;
    color: #F7FCFF;
}
.page-sub-title svg{
    color: #F7FCFF;
}
.page-sub-title a{
    font: normal normal normal 18px/25px Tajawal;
    letter-spacing: 0.36px;
    color: #F7FCFF;
}
.page-sub-title a:hover{
    color: #F7FCFF;
}
@media (max-width:576px){
	.page-title h4{
		font: normal normal bold 24px/32px Tajawal;
	}
    .page-sub-title a{
        font: normal normal normal 16px/22px Tajawal;
    }
    .page-sub-title span{
        font: normal normal normal 16px/22px Tajawal;
    }
}